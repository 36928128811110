<template>
  <b-card>
    <h2>Вопросы</h2>

    <b-table
        striped
        hover
        responsive
        :per-page="0"
        :current-page="filters.page"
        :items="questionsList"
        :fields="columns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        id="questions-list"
        sidePagination="server"
    >
      <template #cell(avatar)="data">
        <b-avatar :src="data.item.avatar" />
      </template>

      <template #cell(status)="data">
        <b-badge :variant="statusVariant(data.item.status)">
          {{ data.item.status }}
        </b-badge>
      </template>

      <template #cell(linked_movies)="data">
        {{ data.item.movies.length }}
      </template>

      <template #cell(author)="data">
        {{ data.item.author.name }}
      </template>

      <template #cell(question)="data">
        <b-link
            :to="{ name: 'question-edit', params: { id: data.item.id } }"
            class="font-weight-bold"
        >
          {{ data.question }}

          <b-avatar
              v-if="data.item.question_url"
              :src="data.item.question_url"
              class="mx-1"
              size="50"
          />
        </b-link>
      </template>

      <template #cell(answer)="data">
        <span v-if="data.item.answer_visible">
            {{ data.item.answer }}
          </span>
        <span v-else>
            **************
          </span>
        <span class="right">
            <feather-icon
                :icon="(data.item.answer_visible)?'EyeOffIcon':'EyeIcon'"
                class="cursor-pointer"
                @click="data.item.answer_visible = !data.item.answer_visible"
            />
          </span>
      </template>

      <template #cell(action)="data">
        <span>
            <b-link
                :to="{ name: 'question-edit', params: { id: data.item.id } }"
                class="font-weight-bold"
            >
              <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
              />
            </b-link>

            <feather-icon
                icon="TrashIcon"
                class="mr-50"
            />
          </span>
      </template>
    </b-table>

    <b-pagination
        v-model="filters.page"
        :total-rows="total"
        :per-page="filters.perPage"
        aria-controls="questions-list"
    ></b-pagination>

  </b-card>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BLink, BCard, BTable
} from 'bootstrap-vue';
import store from '@/store';
import questionStoreModule from '@/views/questions/questionStoreModule';
import {onUnmounted, ref, watch} from '@vue/composition-api';
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BLink,
    BTable,
  },
  data () {
    return {
      columns: [
        {
          label: 'Вопрос',
          key: 'question',
        },
        {
          label: 'Ответ',
          key: 'answer',
        },
        {
          label: 'Дата',
          key: 'created_at',
          sortable: true,
        },
        {
          label: 'Тема',
          key: 'theme_name',
          sortable: true,
        },
        {
          label: 'Автор',
          key: 'author',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: 'Фильтр по автору', // placeholder for filter input
            filterDropdownItems: [
              { value: 1, text: 'Дед' },
              { value: 2, text: 'Анна Васильева' },
              { value: 3, text: 'Кирилл Анисимов' },
              { value: 4, text: 'Антон Новиков' },
              { value: 5, text: 'Gnomi' },
              { value: 6, text: 'Кирилл Романов' },
              { value: 7, text: 'Вика' },
            ],
            filterFn: this.authorFilterFn, //custom filter function that
            //trigger: 'enter', //only trigger on enter not on keyup
          },
        },
        {
          label: 'Привязанные',
          key: 'linked_movies',
        },
        {
          label: 'Действия',
          key: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [
        {
          1: 'Добавлен',
          2: 'Принят',
          3: 'Отклонен',
          4: 'Дубль',
          5: 'Использован',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        }],
    };
  },
  computed: {
    statusVariant () {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
        /* eslint-enable key-spacing */
      };

      return status => statusColor[status];
    },
  },
  setup () {
    const QUESTIONS_APP_STORE_MODULE_NAME = 'app-questions'

    // Register module
    if (!store.hasModule(QUESTIONS_APP_STORE_MODULE_NAME)) store.registerModule(QUESTIONS_APP_STORE_MODULE_NAME, questionStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QUESTIONS_APP_STORE_MODULE_NAME)) store.unregisterModule(QUESTIONS_APP_STORE_MODULE_NAME)
    })

    const filters = ref({
      q: '',
      categories: [],
      author_id: null,
      page: 1,
      perPage: 15,
    })
    const sortBy = ref('dt')
    const sortDesc = ref(true)

    const questionsList = ref([])
    const total = ref(null)
    const fetchQuestions = (...args) => store.dispatch('app-questions/fetchQuestionsList', ...args)

    const isLoadingData = ref(false);

    const fetchQuestionsList = () => {
      isLoadingData.value = true
      fetchQuestions({
        q: filters.value.q,
        author_id: filters.value.author_id,
        sortBy: (sortDesc.value ? '-' : '') + sortBy.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
      })
          .then(response => {
            questionsList.value = response.data.data.map(function(item) {
              item.answer_visible = false
              return item
            })
            total.value = response.data.meta.total
            isLoadingData.value = false
          })
    }

    const authorFilterFn = (data, filterString) => filters.value.author_id = parseInt(filterString);

    fetchQuestionsList()

    watch([filters, sortBy, sortDesc], () => {
      fetchQuestionsList()
    }, {
      deep: true,
    })

    return {
      questionsList,
      filters,
      authorFilterFn,
      isLoadingData,
      sortBy,
      sortDesc,
      total,
      formatDate,
    }
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
